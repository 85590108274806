import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import { Head } from "../components/Header/Head";

import './project.scss'

const Project = () => {
  return (
    <Layout>

      <Head content={`Project`} />

      <section className="section__project__page">

        <Aproject
          number='#1'
          heading='Chat Application'
          description='Chat Application where a  user create room and other user can join that group and can text any one in that group.'
          tech="node express.js socket.io"
          liveLink={"https://binchat.herokuapp.com/"}
          gitLink={"https://github.com/Nabiin/chatapp"}
        />
      </section>
    </Layout>
  );
};


const Aproject = ({ number, heading, description, tech, pic, gitLink, liveLink }) => {
  // const [showImage, setShowImage] = useState(false)
  tech = tech.split(' ')
  return (
    <div className="wrapper">
      <div className="wrapper__1">
        <h3>
          <span>
            {number} &nbsp;
          </span>
          {heading}
        </h3>
        <p>
          {description}
        </p>
        <div className="techUsed">
          <h4>Tech used :</h4>
          <div className="tech">
            {
              tech.map(value => <span key={value}>{value}</span>)
            }
          </div>
        </div>
      </div>
      <div className="wrapper__2">
        <div className="btn--div">
          <Link className='btn-primary btn-v2' activeClassName="active" to={gitLink} target="_blank">
            git link
            <i className="bi bi-arrow-right-short"></i>
          </Link>
        </div><div className="btn--div">
          <Link className='btn-primary btn-v2' activeClassName="active" to={liveLink} target="_blank">
            Live Demo
            <i className="bi bi-arrow-right-short"></i>
          </Link>
        </div>
      </div>
    </div >
  )
}













// const Project = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       allMarkdownRemark(filter: { frontmatter: { type: { eq: "project" } } }) {
//         edges {
//           node {
//             frontmatter {
//               title
//               date
//             }
//             fields {
//               slug
//             }
//           }
//         }
//       }
//     }
//   `);
//   return (
//     <Layout>
//       <Head title="Projects"></Head>
//       <Heading title={'Project'} icon={'🎉'} />
//       <div className="post__container">
//         <div className="post__container--1">
//           <div className="pagination__container">
//             <h1 style={{paddingLeft:'2rem' , transform:'translateY(-1rem)', marginBottom:'1rem' }}>Projects</h1>

//           </div>
//         </div>
//         {/* <Tags item={props.data.tagsGroup.group} /> */}
//       </div>
//       <ul>
//         {data.allMarkdownRemark.edges.map((edge) => {
//           console.log(JSON.stringify(edge));
//           return (
//             <li key={edge.node.fields.slug}>
//               <Link to={`/project/${edge.node.fields.slug}`}>
//                 <h2>{edge.node.frontmatter.title}</h2>
//                 <p>Chat app</p>
//               </Link>
//             </li>
//           );
//         })}
//       </ul>

//       {/* <ul>
//         {data.allMarkdownRemark.edges.forEach((edge) => {
//           //we know post contain date and we also know my project dont contain date to
//           //project will be filter out by this condition
//           if (edge.node.frontmatter.date)
//             return (
//               <li>
//                 <Link to={`/project/${edge.node.fields.slug}`}>
//                   <h2>{edge.node.frontmatter.title}</h2>
//                   <p>Chat app</p>
//                 </Link>
//               </li>
//             );
//         })}
//       </ul> */}
//     </Layout>
//   );
// };

export default Project;
